import {
    transition,
    trigger,
    query,
    style,
    animate,
    group,
    animateChild
} from '@angular/animations';


export const slideInAnimation =
    trigger('routeAnimations', [
        transition('* => home', [
            query(':enter, :leave', style({ position: 'absolute', width: '100%', background: 'transparent', zIndex: '99' }), { optional: true }),
            group([
                query(':enter', [
                    style({ opacity: 1, top: '0',  position: 'fixed' }),
                    animate('0s ease', style({ opacity: 1, top: '0',  position: 'fixed'}))
                ], { optional: true }),
                query(':leave', [
                    style({ opacity: 1, top: '0' }),
                    animate('1s ease', style({ opacity: 0, top: '-20vh'  }))
                ], { optional: true }),
            ])
        ]),

        transition('* <=> *', [
            query(':enter, :leave', style({ position: 'relative', width: '100%', zIndex: '99' }), { optional: true }),
            group([
                query(':leave', [
                    style({ opacity: 1, transform: 'translateY(0)' }),
                    animate('1s ease', style({ opacity: 0, transform: 'translateY(20vh)'  }))
                ], { optional: true }),
                query(':enter', [
                    style({ opacity: 0, transform: 'translateY(20vh)',  position: 'fixed' }),
                    animate('1s ease', style({ opacity: 1, transform: 'translateY(0)',  position: 'fixed'}))
                ], { optional: true })
            ])
        ])

    ]);
// `translateY(${window.pageYOffset}`
