import {Component, Input, OnInit, HostListener, ElementRef } from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

@Component({
  selector: 'app-what',
  templateUrl: './what.component.html',
  styleUrls: ['./what.component.scss'],
    animations: [
        trigger('shadowAnimation', [
            state('show', style({
                // borderBottom: '1px solid #333',
                // transform: "translateX(0)"
            })),
            state('hide',   style({
                // borderBottom: '1px solid #fff',
                // transform: "translateX(-100%)"
            })),
            // transition('show => hide', animate('400ms ease-in')),
            transition('hide => show', animate('400ms ease-in'))
        ]),
        trigger('scrollAnimation', [
            state('show', style({
                opacity: 1,
                // transform: "translateX(0)"
            })),
            state('hide',   style({
                opacity: 0,
                // transform: "translateX(-100%)"
            })),
            // transition('show => hide', animate('800ms ease-in')),
            transition('hide => show', animate('800ms 800ms ease-in'))
        ]),
        trigger('riseAnimation', [
            state('show', style({
                // opacity: 1,
                transform: "translateY(0)"
            })),
            state('hide',   style({
                // opacity: 0,
                transform: "translateY(100%)"
            })),
            // transition('show => hide', animate('800ms ease-out')),
            transition('hide => show', animate('800ms ease-in'))
        ])
    ]
})
export class WhatComponent implements OnInit {

  state = 'hide';
  @Input() what;

  constructor(public el: ElementRef) { }
  @HostListener('window:scroll', ['$event'])
     checkScroll() {
        const componentPosition = this.el.nativeElement.offsetTop - window.innerHeight / 1.5;
        const scrollPosition = window.pageYOffset;

        if (scrollPosition >= componentPosition) {
            this.state = 'show'
        // } else {
        //     this.state = 'hide'
        }

    }


  ngOnInit() {
  }

}
