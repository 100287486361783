import {Component, OnInit, HostListener, ElementRef, Input, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faMedium} from '@fortawesome/free-brands-svg-icons';
import {Subject} from "rxjs/index";
import {AppComponent} from "../../app.component";
import {first, takeUntil} from "rxjs/internal/operators";

// import {faBu}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() navbarColor;
  private readonly _destroyed: Subject<void> = new Subject();
    public faTwitter = faTwitter;
    public faMedium = faMedium;
  public navbar: any;
  public mobileNavActive: boolean;
  public navbarDark: boolean;
  public navbarTransparent: boolean;
  public navbarClasses: object;

  constructor(private router: Router, private el: ElementRef, public app: AppComponent) {
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        // window.scrollTo(0, 0);
        document.body.classList.remove('modal-open');
      }
    });
    app.done$
      .pipe(first(), takeUntil(this._destroyed))
      .subscribe(()=> {
          window.scrollTo(0, 0);
      });

    app.start$
        .pipe(first(), takeUntil(this._destroyed))
        .subscribe(()=> {
            // this.navbar.classList.remove('short');
            // this.navbar.style.backgroundColor = 'transparent !important';
            // this.navbar.classList.add('navbar-light', 'bg-transparent');
            // this.navbar.classList.remove('bg-white');
        });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.scrollY > 200) {
      this.navbar.classList.add('short');
      // if (this.navbarTransparent && !this.navbarDark) {
      //   this.navbar.classList.remove('navbar-dark', 'bg-transparent');
      //   this.navbar.classList.add('navbar-light', 'bg-white');
      // }
    } else {
      this.navbar.classList.remove('short');
      // if (this.navbarTransparent && !this.navbarDark) {
        // this.navbar.classList.add('navbar-dark', 'bg-transparent');
        // this.navbar.classList.remove('navbar-light', 'bg-white');
      // }
    }
  }

  ngOnInit() {
    // if (this.navbarColor === 'dark') {
    //   this.navbarDark = true;
    //   this.navbarTransparent = false;
    // } else if (this.navbarColor === 'transparent') {
    //   this.navbarDark = false;
    //   this.navbarTransparent = true;
    // }

    this.navbar = this.el.nativeElement.children[0];

    this.navbarClasses = {
      'bg-primary': this.navbarDark,
      'navbar-light': !this.navbarDark && !this.navbarTransparent,
      'navbar-dark bg-transparent': this.navbarTransparent
    };
  }

  ngOnDestroy() {

  }

  navbarToggle() {
    this.mobileNavActive = !this.mobileNavActive;
    this.mobileNavActive ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open');
  }

}
