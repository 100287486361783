import { Component, OnInit } from '@angular/core';
import { WhatModel } from '../models/what.model';
import { GetDataService } from '../services/get-data.service';
import { Title } from '@angular/platform-browser';
import {ProjectModel} from "../models/project.model";

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  public what: WhatModel[];

  constructor(private getData: GetDataService, private title: Title) {
      this.getData.getWhat().subscribe(what => {
          this.what = what;
      });
  }

  ngOnInit() {
      this.title.setTitle('About Us');
  }

}
