import { Component, Renderer2 } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import {slideInAnimation} from './animations';
import {RouterOutlet} from '@angular/router';
import {Subject} from "rxjs/index";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    animations: [
        slideInAnimation
        // animation triggers go here
    ]
})
export class AppComponent {
      previousUrl: string;
 
  constructor(private renderer: Renderer2, private router: Router) {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, 'page-' + this.previousUrl);
            this.renderer.removeClass(document.body, 'page-home');
          }
          let currentUrlSlug = event.url.slice(1)
          if (currentUrlSlug) {
              this.renderer.removeClass(document.body, 'page-home');
            this.renderer.addClass(document.body, 'page-' + currentUrlSlug);
          } else {
            this.renderer.addClass(document.body, 'page-home');
          }
          this.previousUrl = currentUrlSlug;
        }
      });
 
  }
    public start$: Subject<AnimationEvent> = new Subject();
    public done$: Subject<AnimationEvent> = new Subject();
    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
}
