import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-past-clients',
  templateUrl: './past-clients.component.html',
  styleUrls: ['./past-clients.component.scss']
})
export class PastClientsComponent implements OnInit {

  constructor(private title: Title) { }

  ngOnInit() {
      this.title.setTitle('Past Clients');
  }

}
