import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';

interface MailChimpResponse {
    result: string;
    msg: string;
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})

export class ContactFormComponent implements OnInit {

  @Input() formDark;
  public form: NgForm;
  public name: string;
  public email: string;
  public message: string;
  public mouseOverSubmit: boolean;
  public submitTrigger: boolean;

    submitted = false;
    mailChimpEndpoint = 'https://cleverangle.us13.list-manage.com/subscribe/post-json?u=8cd898908c9e0274816fbbc35&id=d1779b2e98&';
    error = '';

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    console.log(this.form);
  }

  submit(contactForm: NgForm) {
    this.error = '';
    let mailName = 'me';
    let mailEmail = 'mikeaskew4+1256@gmail.com';
    let mailMessage = 'Hi There';
    console.log(contactForm);
    if (contactForm) {
        mailName = contactForm.name;
        mailEmail = contactForm['email'];
        mailMessage = contactForm['message'];
    }
    this.submitTrigger = true;
      // if (this.contactForm.status === 'VALID' && this.contactForm.status === 'VALID') {

          const params = new HttpParams()
              .set('NAME', mailName)
              .set('EMAIL', mailEmail)
              .set('MESSAGE', mailMessage)
              .set('b_123abc123abc123abc123abc123abc123abc', ''); // hidden input name

          const mailChimpUrl = this.mailChimpEndpoint + params.toString();

          // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
          this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
              if (response.result && response.result !== 'error') {
                  this.submitted = true;
              }
              else {
                  this.error = response.msg;
              }
          }, error => {
              console.error(error);
              this.error = 'Sorry, an error occurred.';
          });
      // }
  }
}
