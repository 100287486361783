import {Component, OnInit, Input, ViewChild, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {faMedium} from '@fortawesome/free-brands-svg-icons';
import {faDribbble} from '@fortawesome/free-brands-svg-icons';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';
import { HostListener } from '@angular/core';
import {AppComponent} from "../../app.component";
import {first, takeUntil} from "rxjs/internal/operators";
import {Subject} from "rxjs/index";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  private readonly _destroyed: Subject<void> = new Subject();
  @Input() light;
  @ViewChild('footer') footer;

  public faInstagram = faInstagram;
  public faMedium = faMedium;
  public faDribbble = faDribbble;
  public faLinkedinIn = faLinkedinIn;
  public faTwitter = faTwitter;
  public isHome: boolean;
  public visible: boolean;

  constructor(private router: Router, public app: AppComponent) {
    this.visible = false;
    this.router.events.subscribe(events => {
      if (events instanceof NavigationEnd) {
        events.url === '/home' || events.url === '/' || events.url === '/contact' ? this.isHome = true : this.isHome = false;
      }
    });
      app.done$
          .pipe(first(), takeUntil(this._destroyed))
          .subscribe(()=> {
              window.scrollTo(0, 0);
          });

      app.start$
          .pipe(first(), takeUntil(this._destroyed))
          .subscribe(()=> {
              this.visible = false;
              // this.navbar.classList.remove('short');
              // this.navbar.style.backgroundColor = 'transparent !important';
              // this.navbar.classList.add('navbar-light', 'bg-transparent');
              // this.navbar.classList.remove('bg-white');
          });
  }


  @HostListener('window:scroll', ['$event']) onScrollEvent($event){
      const scrollPosition = window.pageYOffset;

      if (scrollPosition > (window.innerHeight / 2)) {
        this.visible = true;
      } else {
        this.visible = false;
      }
  }

  ngOnInit() {
      // var footer = document.getElementById(( '#footer' ) );
      // var footHeight = this.footer.nativeElement.scrollHeight;
      // console.log(this.footer.nativeElement.scrollHeight);
      // document.body.style.marginBottom = footHeight * 2 + 'px';
  }

    ngOnDestroy() {

    }
}
