import { Component, OnInit } from '@angular/core';
import {ProjectModel} from '../models/project.model';
import {GetDataService} from '../services/get-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-projects',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})

export class ProjectComponent implements OnInit {
  public project: ProjectModel[];
  public slug: string;
  public ready: boolean;

  constructor(private getData: GetDataService, private route: ActivatedRoute ) {
      this.slug = this.route.snapshot.paramMap.get('slug');
      this.getData.getProjectById(this.slug).subscribe(project => {
          this.project = project;
          this.ready = true;
      });
  }

  ngOnInit() {
    this.ready = false;
  }

}
