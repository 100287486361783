import {Component, OnInit, ViewChild} from '@angular/core';
import * as Rellax from 'rellax/rellax';
import {faJs} from '@fortawesome/free-brands-svg-icons';
import {faNodeJs} from '@fortawesome/free-brands-svg-icons';
import {faNpm} from '@fortawesome/free-brands-svg-icons';
import {faHtml5} from '@fortawesome/free-brands-svg-icons';
import {faCss3} from '@fortawesome/free-brands-svg-icons';
import {faSass} from '@fortawesome/free-brands-svg-icons';
import {faBootstrap} from '@fortawesome/free-brands-svg-icons';
import {faAngular} from '@fortawesome/free-brands-svg-icons';
import {faReact} from '@fortawesome/free-brands-svg-icons';
import {faDrupal} from '@fortawesome/free-brands-svg-icons';
import {faGit} from '@fortawesome/free-brands-svg-icons';
import {faGitlab} from '@fortawesome/free-brands-svg-icons';
import {faAdobe} from '@fortawesome/free-brands-svg-icons';
import {faGulp} from '@fortawesome/free-brands-svg-icons';
import {faAws} from '@fortawesome/free-brands-svg-icons';
import {faWordpressSimple} from '@fortawesome/free-brands-svg-icons';
import {faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons';
import {faLongArrowAltRight} from '@fortawesome/free-solid-svg-icons';
import {faPaintBrush} from '@fortawesome/free-solid-svg-icons';
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {faSlideshare} from "@fortawesome/free-brands-svg-icons/faSlideshare";
import {faHeart} from "@fortawesome/free-solid-svg-icons/faHeart";
import {faBullhorn} from "@fortawesome/free-solid-svg-icons/faBullhorn";
import {faNewspaper} from "@fortawesome/free-solid-svg-icons";
import {faParagraph} from "@fortawesome/free-solid-svg-icons/faParagraph";
import {Title, Meta} from "@angular/platform-browser";



@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    keywords = "creative, advertising, branding, digital, marketing, web development, growth hacking";
    description = "A Brand Experience Agency";
    pageTitle = "CLEVERANGLE: Modern Marketing for a Tech-Infused World";


    @ViewChild('video') video;
    public interval;
    public icons = [];
    public c_icons = [];
    public arrows = [];
    public desktop: boolean;
    public slides = [
        {img: '/assets/img/team/team-1.jpg'},
        {img: '/assets/img/team/team-2.jpg'},
        {img: '/assets/img/team/team-3.jpg'},
        {img: '/assets/img/team/team-4.jpg'},
        {img: '/assets/img/team/team-5.jpg'},
        {img: '/assets/img/team/team-6.jpg'},
        {img: '/assets/img/team/team-7.jpg'}
    ];
    public slideConfig = {
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
    };

    constructor(private title: Title, private meta: Meta) {
        this.arrows.push(faLongArrowAltLeft, faLongArrowAltRight);
        this.desktop = window.screen.width > 1024;
    }

    ngOnInit() {
        this.title.setTitle(this.pageTitle);
        this.meta.addTags([
            {name: 'description', content: this.description},   
            {name: 'viewport', content: 'width=device-width, initial-scale=1' },   
            {name: 'robots', content: 'INDEX, FOLLOW' },
            {name: 'author', content: 'CLEVERANGLE' },
            {name: 'keywords', content: this.keywords },
            {name: 'date', content: '2018-06-02', scheme: 'YYYY-MM-DD' },
            {httpEquiv: 'Content-Type', content: 'text/html' },
            {property: 'og:title', content: this.pageTitle },
            {property: 'og:type', content: "website" },
            {property: 'og:image', content: "https://www.cleverangle.com/assets/img/ca-facebook.jpg" },
            { name: 'twitter:card', content: "summary_large_image" },
            { name: 'twitter:site', content: "cleverangle" },
            { name: 'twitter:title', content: this.pageTitle },
            { name: 'twitter:description', content: this.description },
            { name: 'twitter:image', content: "https://www.cleverangle.com/assets/img/ca-twitter.jpg" },
            {charset: 'UTF-8'}
        ]);       
        this.icons.push(
            faHtml5,
            faCss3,
            faSass,
            faJs,
            faNodeJs,
            faNpm,
            faAngular,
            faReact,
            faDrupal,
            faGit,
            faGitlab,
            faAdobe,
            faGulp,
            faAws,
            faWordpressSimple
        );
        this.c_icons.push(
            faPaintBrush,
            faPaperPlane,
            faSlideshare,
            faHeart,
            faBullhorn,
            faNewspaper,
            faParagraph
        );
        (window as any).rellax = new Rellax('.rellax');
    //
    //     var video = document.getElementById('lines');
    //     // video.setAttribute('src', 'video/clever.mp4');
        console.log(this.video);
        this.video.nativeElement.currentTime = 0;
        this.video.nativeElement.muted = true;
        this.video.nativeElement.loop = false;
        this.video.nativeElement.load();
        // if (this.desktop) {
        //     this.interval = setInterval(a => {
                this.video.nativeElement.play();
                // clearInterval(this.interval);
            // }, 2500, []);
        // }
    }

    @ViewChild('teamCarousel') teamCarousel;

    playVideo(){
        this.video.nativeElement.play();
    }

    prevSlide() {
        this.teamCarousel.slickPrev();
    }

    nextSlide() {
        this.teamCarousel.slickNext();
    }

}
